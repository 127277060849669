:root {
  --yellow: #e9e928;
  /* --green: #16a085; */
}

/* *{
	padding:0px;
	margin:0px;
}
body{
		background:black;
	} */
.st0 {
  font-family: "FootlightMTLight";
}
.st1 {
  font-size: 83.0285px;
}
.st2 {
  fill: gray;
}

.error-page {
  width: 400px;
  height: 300px;
  text-align: center;
  fill: white;
}
@media (min-width: 960px) {
  .error-page {
    width: 500px;
    height: 400px;
    text-align: center;
    fill: white;
  }
}

path#XMLID_5_ {
  fill: var(--yellow);
  filter: url(#blurFilter4);
}
path#XMLID_11_,
path#XMLID_2_ {
  fill: var(--yellow);
}
.circle {
  animation: out 2s infinite ease-out;
  fill: var(--yellow);
}

#container {
  text-align: center;
  padding: 15px;
  position: absolute;
  top: 40%;
  left: 50%;
  -ms-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.message {
  color: white;
}
.message:after {
  content: "]";
}
.message:before {
  content: "[";
}

.message:after,
.message:before {
  color: var(--yellow);
  font-size: 20px;
  -webkit-animation-name: opacity;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: opacity;
  animation-name: opacity;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  margin: 0 50px;
}

.return-home {
  font-size: 1.8rem;
  width: auto;
  min-width: 180px;
  min-height: 67px;
  background-color: transparent;
  background-repeat: no-repeat;
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.04))
    drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.06))
    drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.04));
  border: none;
  border: 2px solid #ffffff;
  color: white;
  border-radius: 2rem;
  transition: all 0.2s ease-in;
  /* margin-left: 3rem; */
  /* margin-right: 0.7rem; */
  cursor: pointer;
}

.return-home:hover {
  background-color: white;
  color: black;
  transform: translateY(-5px);
}

@-webkit-keyframes opacity {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@keyframes opacity {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@keyframes out {
  0% {
    r: 1;
    opacity: 0.9;
  }
  25% {
    r: 5;
    opacity: 0.3;
  }
  50% {
    r: 10;
    opacity: 0.2;
  }
  75% {
    r: 15;
    opacity: 0.1;
  }
  100% {
    r: 20;
    opacity: 0;
  }
}
