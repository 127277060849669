/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap"); */

/* @font-face {
  font-family: "SFProDisplay-Regular";
  font-weight: 400;
  src: local("SFProDisplay-Regular"),
    url(./fonts/SFProDisplay-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "SFProText-Regular";
  src: local("SFProText-Regular"),
    url(./fonts/SFProText-Regular.ttf) format("truetype");
} */
/* @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap'); */
* {
  /* box-sizing: border-box; */
  margin: 0;
  padding: 0;
}
/* .toastProgress {
  background: #E9E92A !important;
} */
.Toastify__progress-bar--success {
  background: #E9E92A !important;
}

html, body {
  font-family: "Roboto", sans-serif;
  /* margin: 0; */
  /* padding: 0; */
  background-color: black;
  color: white;
  line-height: 1.6;
  overflow: hidden visible;
  /* overflow-x: hidden; */
}
/* section {
  width: 100%;
} */
body:lang(he) {
  font-family: "Assistant";
}
button {
  font-family: "Roboto", sans-serif;
}

input {
  -webkit-border-radius:0; 
  border-radius:0;
}

::-webkit-scrollbar {
  width: 1em;
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.61);
  border-radius: 100vw;
  margin-block: 0.5em;
}

::-webkit-scrollbar-thumb {
  background: white;
  border: 0.3em solid black;
  border-radius: 100vw;
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(219, 207, 207);
}

/* background-image: linear-gradient(to bottom, rgba(243, 239, 10, 1), rgb(255, 255, 200)); */

/* ::-ms-placeholder {
  color:white;
} */

/* ::placeholder {
  color:white;
} */

/* Chrome/Opera/Safari */
::-webkit-input-placeholder {
  /* color: white; */
  opacity: 1;
}

/* Firefox 19+ */
::-moz-placeholder {
  /* color: white; */
  opacity: 1;
}

/* IE 10+ */
/* :-ms-input-placeholder { 
  color: white;
} */

/* Firefox 18- */
/* :-moz-placeholder { 
  color: white;
} */

/* Loading spinner while Suspense activated */

.lds-spinner {
  /* color: official; */
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  left: 40%;
}

@media (min-width: 960px) {
  .lds-spinner {
    /* color: official; */
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    left: 48%;
  }
}

.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: #e9e928;
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
