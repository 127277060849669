/* .container {

}  */

.dropdown {
  width: 100px;
  user-select: none;
  margin: 100px auto;
  position: relative;
  /* background-color: yellow; */
}

.dropdown .dropdown-btn {
  padding-bottom: 8px;
  padding-right: 10px;
  padding-left: 15px;
  /* padding: 8px 5px; */
  background: transparent;
  /* box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.06); */
  font-weight: bold;
  color: white;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  overflow: hidden;
  &:hover {
    /* text-decoration: underline; */
    color: rgb(236, 236, 216);
  }
  
}
.dropdown .dropdown-btn:lang(he) {
  font-size: 17px;
}

/* .dropdown-btn:hover {
  background: #e5e2e21f;
} */

/* .dropdown-btn:after {
  content: "";
  background: #eceeec;
  width: 25px;
  position: absolute;
  padding-top: 40%;
  padding-left: 80%;
  margin-top: 10%;
  opacity: 0;
  transition: all 0.5s
}

.dropdown-btn:active:after {
  padding: 0;
  margin: 0;
  opacity: 1;
  transition: 0s
} */

.dropdown .dropdown-content {
  position: absolute;
  min-width: 112px;
  max-width: 280px;
  overflow: auto;
  max-height: calc(100vh - 48px);
  border-radius: 8px;
  outline: 0;
  min-height: 64px;
  top: 105%;
  left: 15%;
  right: 10%;
  padding: 5px;
  background: #fff;
  box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.2);
  font-weight: 600;
  color: #333;
  /* width: 95%; */
  font-size: 16px;
  /* background-image: linear-gradient(to left, rgba(255, 253, 123, 0.911), rgb(255, 255, 255)); */
  /* background-color: yellow; */
}

.dropdown .dropdown-content .dropdown-item {
  padding: 5px;
  cursor: pointer;
  transition: all 0.3s;
  /* background-color: yellow; */
}

.dropdown .dropdown-content .dropdown-item:hover {
  background: #e5e2e2;
  border-radius: 6px;
  /* background-color: yellow; */
}

.flag-icon {
  /* background-image: url(../../../public/assets/locales/Images/il.svg); */
  padding: 0;
  margin: 0;
  /* background-color: yellow; */
}
.flag-icon-svg {
  width: 20%;
  padding-right: 0.45em;
  padding-left: 0.45em;
}

.flag-icon-menu {
  width: 23%;
  width: 17.5px;
  padding-right: 0.45em;
  padding-left: 0.45em;

}
