.NotifyForm.rtl {
  direction: rtl;
}

.NotifyForm.ltr {
  direction: ltr;
}

.email_input:lang(he),
.city_input:lang(he) {
  font-family: "Assistant";
}






.NotifyForm {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-around;
  align-items: flex-start;
}

.input-container {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column; /* Default to column for smaller screens */
  gap: 10px; /* Space between input fields */
}
.input-container:lang(he) {
  margin-bottom: 30px;
}

.input-container .form-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}


@media (max-width: 480px) {
  /* Universal fix for RTL field width (Mobile only) */
  .input-container:lang(he) .email_input,
  .input-container:lang(he) .city_input {
    width: 290px !important; /* Force field width on mobile */
    max-width: 100%; /* Maintain responsiveness */
  }
}



@media (min-width: 960px) {
  .input-container {
    flex-direction: row; /* Change to row for larger screens */
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
  }

 
}

.submit_btn {
  font-size: 1.3rem;
  width: 100%;
  min-width: 190px;
  max-width: 202px;
  max-height: 42px;
  min-height: 54px;
  height: 55px;
  border: none;
  color: black;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 2rem;
  padding: 0 1rem;
  transition: all 0.2s ease-in;
  align-content: center;
  text-align: center;
  text-align-last: center;
  font-weight: 400;
}

.submit_btn:hover:enabled {
  filter: contrast(200%);
  color: black;
  transform: translateY(-5px);
  text-align: center;
  text-align-last: center;
  cursor: pointer;
}

.submit_btn:disabled {
  opacity: 80%;
}

@media (max-width: 959px) {
  .NotifyForm {
    align-items: flex-start;
  }

  .input-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

  }

  .input-container:lang(en) > div {
    margin-bottom: 5px;
    text-align: left;
  }

  .input-container:lang(he) label {
    /* display: block; */
    text-align: right;
    margin-right: 0px; 
    
  }
  
  .input-container:lang(he) {
    justify-items: flex-end;
    
  }

  .input-container:lang(he) label {
    text-align: right;
  }

  .submit_btn {
    font-size: 1rem;
    height: 45px;
    min-height: 45px;
    max-height: 45px;
    min-width: 140px;
    max-width: 152px;
  }
}

@media (min-width: 960px) {
  .NotifyForm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  /* .input-container:lang(he) label {
    display: block;
    margin-bottom: 10px;
    margin-right: 10px; 
    
  } */

  .btn {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 10px;
  }

  .submit_btn {
    width: auto;
    max-width: 200px;
  }
}

.email_input,
.city_input {
  width: 100%;
  max-width: 450px; /* Increase the max-width to make the fields longer */
  min-width: 280px;
  /* height: 40px; */
  border: 1px solid #939182;
  border-radius: 7px;
  padding: 5px 10px;
  background-color: transparent;
  margin: 0.65rem 0;
  border-width: 1px;
  color: #ffff;
  font-weight: 400;
  font-size: 1.1rem;
  opacity: 1;
  transition: border-color 0.3s ease;
}

.custom-width {
  width: 30ch !important; /* Apply the custom width */
}
